import * as m from "@ollie-sports/models";
import type firebase from "firebase/compat";
import {
  FirestoreLiftCollection,
  createFirestoreLift,
  TypedFirebaseObjectOrPrimativeRefGenerator,
  createRtdbLift,
  DocumentWriteChange
} from "@ollie-sports/firebase-lift";
import * as _ from "lodash";
import { DeviceRegistration, KeyValueStore, Poll, SimpleHeartBeat, SoccerGameRTDBInfo } from "@ollie-sports/models";

export { BatchTask } from "@ollie-sports/firebase-lift";

interface Example {
  id: string;
  name: string;
  age: number;
}

export interface OllieFirestoreLift {
  Account: FirestoreLiftCollection<m.Account>;
  AccountPrivate: FirestoreLiftCollection<m.AccountPrivate>;
  AccountSecret: FirestoreLiftCollection<m.AccountSecret>;
  ActionRequest: FirestoreLiftCollection<m.ActionRequest>;
  CalendarEntry: FirestoreLiftCollection<m.CalendarEntry>;
  ChecklistItem: FirestoreLiftCollection<m.ChecklistItem>;
  Code: FirestoreLiftCollection<m.Code>;
  Conversation: FirestoreLiftCollection<m.Conversation>;
  Email: FirestoreLiftCollection<m.Email>;
  License: FirestoreLiftCollection<m.License>;
  LicenseHistory: FirestoreLiftCollection<m.LicenseHistory>;
  LiveGameReaction: FirestoreLiftCollection<m.LiveGameReaction>;
  LiveGameCustomEvent: FirestoreLiftCollection<m.LiveGameCustomEvent>;
  LowPriorityNotificationDetail: FirestoreLiftCollection<m.LowPriorityNotificationDetail>;
  Message: FirestoreLiftCollection<m.Message>;
  Player: FirestoreLiftCollection<m.Player>;
  PlayerBundle: FirestoreLiftCollection<m.PlayerBundle>;
  SoccerGame: FirestoreLiftCollection<m.SoccerGame>;
  SoccerGameEvent: FirestoreLiftCollection<m.SoccerGameEvent>;
  SoccerGameEventBundle: FirestoreLiftCollection<m.SoccerGameEventBundle>;
  SoccerStatSnapshot: FirestoreLiftCollection<m.SoccerStatSnapshot>;
  Sponsor: FirestoreLiftCollection<m.Sponsor>;
  Team: FirestoreLiftCollection<m.Team>;
  TeamSettings: FirestoreLiftCollection<m.TeamSettings>;
  Coupon: FirestoreLiftCollection<m.Coupon>;
  DeferredLink: FirestoreLiftCollection<m.DeferredLink>;
  Example: FirestoreLiftCollection<Example>;
  Poll: FirestoreLiftCollection<m.Poll>;
  School: FirestoreLiftCollection<m.School>;
  Evaluation: FirestoreLiftCollection<m.Evaluation>;
  EvaluationTemplate: FirestoreLiftCollection<m.EvaluationTemplate>;
  LibraryResource: FirestoreLiftCollection<m.LibraryResource>;
  ScoreKeepingEvent: FirestoreLiftCollection<m.ScoreKeepingEvent>;
  OpenOrgEvent: FirestoreLiftCollection<m.OpenOrgEvent>;
  OpenOrgEventRegistration: FirestoreLiftCollection<m.OpenOrgEventRegistration>;
  Org: FirestoreLiftCollection<m.Org>;
  OrgCoupon: FirestoreLiftCollection<m.OrgCoupon>;
  OrgCouponHistoryAction: FirestoreLiftCollection<m.OrgCouponHistoryAction>;
  OrgInvoice: FirestoreLiftCollection<m.OrgInvoice>;
  OrgInvoiceHistoryAction: FirestoreLiftCollection<m.OrgInvoiceHistoryAction>;
  OrgPayment: FirestoreLiftCollection<m.OrgPayment>;
  OrgPaymentRefund: FirestoreLiftCollection<m.OrgPaymentRefund>;
  OrgPaymentPlan: FirestoreLiftCollection<m.OrgPaymentPlan>;
  OrgRegistrationPackage: FirestoreLiftCollection<m.OrgRegistrationPackage>;
  OrgRegistration: FirestoreLiftCollection<m.OrgRegistration>;
  OrgRegistrationSuccessMessage: FirestoreLiftCollection<m.OrgRegistrationSuccessMessage>;
  OrgRegistrationAnswer: FirestoreLiftCollection<m.OrgRegistrationAnswer>;
  OrgRegistrationQuestion: FirestoreLiftCollection<m.OrgRegistrationQuestion>;
  OrgRegistrationQuestionGrouping: FirestoreLiftCollection<m.OrgRegistrationQuestionGrouping>;
  OrgSeason: FirestoreLiftCollection<m.OrgSeason>;
  OrgSettings: FirestoreLiftCollection<m.OrgSettings>;
  OrgSecret: FirestoreLiftCollection<m.OrgSecret>;
  OrgTeamTag: FirestoreLiftCollection<m.OrgTeamTag>;
}

export function generateOllieFirestoreV2(config: {
  firestoreModule: typeof firebase.firestore;
  app: firebase.app.App;
  enforceImmutability?: boolean;
  onDocumentsWritten: (docData: DocumentWriteChange[]) => Promise<void>;
}) {
  const firestoreLiftV2 = createFirestoreLift<OllieFirestoreLift>({
    collections: {
      ...Object.keys(m.AllCollectionNames).reduce((acc, a) => {
        acc[capitalize(a)] = { collection: a };
        return acc;
      }, {} as any),
      // ---------------
      // Config overrides below
      // ---------------
      Account: {
        collection: m.AllCollectionNames.account,
        prefixIdWithCollectionName: false
      },
      AccountPrivate: {
        collection: m.AllCollectionNames.accountPrivate,
        disableIdGeneration: true
      },
      AccountSecret: {
        collection: m.AllCollectionNames.accountSecret,
        disableIdGeneration: true
      },
      SoccerGameEventBundle: {
        collection: m.AllCollectionNames.soccerGameEventBundle,
        disableIdGeneration: true
      },
      SoccerStatSnapshot: {
        collection: m.AllCollectionNames.soccerStatSnapshot,
        disableIdGeneration: true
      },
      Example: {
        collection: "example"
      },
      Coupon: {
        collection: m.AllCollectionNames.coupon,
        prefixIdWithCollectionName: false,
        disableIdGeneration: true
      }
    },
    firebaseApp: config.app as any,
    firestoreModule: config.firestoreModule as any,
    enforceImmutability: config.enforceImmutability,
    onDocumentsWritten: config.onDocumentsWritten
  });

  return firestoreLiftV2;
}

export function generateOllieRtdb(config: { app: firebase.app.App }) {
  const nodes = {
    deviceRegistration: null as unknown as TypedFirebaseObjectOrPrimativeRefGenerator<DeviceRegistration>,
    simpleHeartBeat: null as unknown as TypedFirebaseObjectOrPrimativeRefGenerator<SimpleHeartBeat>,
    deviceRegistrationV2: null as unknown as TypedFirebaseObjectOrPrimativeRefGenerator<Record<string, DeviceRegistration>>,
    simpleHeartBeatV2: null as unknown as TypedFirebaseObjectOrPrimativeRefGenerator<Record<string, SimpleHeartBeat>>,
    soccerGameInfo: null as unknown as TypedFirebaseObjectOrPrimativeRefGenerator<SoccerGameRTDBInfo>,
    keyValueStore: null as unknown as TypedFirebaseObjectOrPrimativeRefGenerator<KeyValueStore>
  };

  return createRtdbLift({ firebaseApp: config.app, nodes });
}

function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}
